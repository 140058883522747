<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" sm="10" lg="5" xl="4" class="image-container">
        <img src="@/assets/images/matriceActionneursCapteurs.webp" alt="" />
      </v-col>
      <v-col cols="12" sm="10" lg="5" xl="5" class="detail">
        <h2>{{ $t("pageActionneurs.title") }}</h2>
        <p>{{ $t("pageActionneurs.intro") }}</p>
        <ul>
          <li>{{ $t("pageActionneurs.details.intelligentAutomation.summary") }}</li>
          <li>{{ $t("pageActionneurs.details.preciseCollection.summary") }}</li>
          <li>{{ $t("pageActionneurs.details.flexibleCustomization.summary") }}</li>
          <li>{{ $t("pageActionneurs.details.animalWellBeing.summary") }}</li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.image-container {
  background-color: var(--bleuclair);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  height: 500px;
  /* Set a fixed height */
}

.image-container img {
  width: 90%;
  object-fit: contain;
  /* Ensures the image scales within the container */
}

.detail {
  padding-left: 50px;
  overflow: auto;
  /* Allows the content to scroll if it becomes too large */
}

.detail ul {
  background-color: white;
  border-radius: 15px;
  padding: 15px 20px;
  list-style-position: inside;
  /* <-- ça force les bullets à rentrer dans le bloc */
}

.detail ul li {
  list-style-type: disc;
  padding: 8px 0;
  margin: 0;
}

@keyframes slideDown {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (max-width: 960px) {
  .detail {
    margin-top: 50px;
  }

  .detail p {
    margin-top: 15px;
  }

  .detail div {
    margin-top: 35px;
  }
}
</style>

<script>
export default {
  name: "App",
  components: {},
};
</script>
