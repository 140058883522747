<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" sm="12" lg="10" xl="9">
        <div class="title-container">
          <h2 style="text-align: left">{{ $t("pageActionneurs.howItWorksTitle") }}</h2>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="12" lg="10" xl="12">
        <div class="fonctionnement-container" ref="container">
          <!-- Étape 01 -->
          <div class="bulle-container">
            <div class="row-between">
              <img src="@/assets/illustrations/ACPositionnement.webp" alt="" />
              <h3>{{ $t("pageActionneurs.steps.installation.stepNumber") }}</h3>
            </div>
            <h5>{{ $t("pageActionneurs.steps.installation.title") }}</h5>
            <p>{{ $t("pageActionneurs.steps.installation.description") }}</p>
          </div>

          <!-- Étape 02 -->
          <div class="bulle-container">
            <div class="row-between">
              <img src="@/assets/illustrations/ACProgrammation.webp" alt="" />
              <h3>{{ $t("pageActionneurs.steps.programming.stepNumber") }}</h3>
            </div>
            <h5>{{ $t("pageActionneurs.steps.programming.title") }}</h5>
            <p>{{ $t("pageActionneurs.steps.programming.description") }}</p>
          </div>

          <!-- Étape 03 -->
          <div class="bulle-container">
            <div class="row-between">
              <img src="@/assets/illustrations/ACCollecteInteraction.webp" alt="" />
              <h3>{{ $t("pageActionneurs.steps.dataCollection.stepNumber") }}</h3>
            </div>
            <h5>{{ $t("pageActionneurs.steps.dataCollection.title") }}</h5>
            <p>{{ $t("pageActionneurs.steps.dataCollection.description") }}</p>
          </div>

          <!-- Étape 04 -->
          <div class="bulle-container">
            <div class="row-between">
              <img src="@/assets/illustrations/ACObservation.webp" alt="" />
              <h3>{{ $t("pageActionneurs.steps.observation.stepNumber") }}</h3>
            </div>
            <h5>{{ $t("pageActionneurs.steps.observation.title") }}</h5>
            <p>{{ $t("pageActionneurs.steps.observation.description") }}</p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="0" lg="1" xl="2"></v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    // Méthode pour défiler vers la gauche
    scrollLeft() {
      const container = this.$el.querySelector(".fonctionnement-container");
      container.scrollBy({
        left: -300, // Distance à défiler
        behavior: "smooth",
      });
    },
    // Méthode pour défiler vers la droite
    scrollRight() {
      const container = this.$el.querySelector(".fonctionnement-container");
      container.scrollBy({
        left: 300, // Distance à défiler
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.title-container {
  width: 100%;
  display: flex;
  justify-content: left;
  margin-bottom: 50px;
  text-align: left;
}

.title-container h2 {
  width: 60%;
}

.fonctionnement-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;
}

.bulle-container {
  width: 380px;
  height: 320px;
  background-color: var(--grisclair);
  border-radius: 20px;
  padding: 20px;
  flex-shrink: 0;
  /* Empêche la réduction de la taille des éléments */
  transition: transform 0.3s ease;
  overflow: hidden;
}

.bulle-container div {
  width: 100%;
  height: 100px;
  align-items: end;
}

.bulle-container div img {
  height: 100%;
  max-width: 75%;
}

.bulle-container div h3 {
  color: var(--vertfonce);
  font-weight: 600;
  font-size: 56px;
}

.bulle-container h5 {
  margin-top: 20px;
}

.bulle-container p {
  margin-top: 15px;
  text-align: justify;
}

.bulle-container:hover {
  transform: scale(1.05);
  /* Agrandit la div de 5% */
  z-index: 10;
  /* Met l'élément agrandi au premier plan */
}

.btn-container {
  position: relative;
  top: 20px;
}

.scroll-btn {
  background-color: var(--grisclair);
  border-radius: 100%;
  width: 60px;
  height: 60px;
  font-size: 30px;
  cursor: pointer;
  z-index: 1;
  align-items: center;
  transition: opacity 0.3s ease;
}

.scroll-btn:hover {
  opacity: 0.5;
}

.scroll-btn.left {
  left: -40px;
  /* Ajustez la position de la flèche gauche */
}

.scroll-btn.right {
  right: -20px;
  /* Ajustez la position de la flèche droite */
}

@media (max-width: 960px) {
  .bulle-container {
    width: 300px;
  }

  .fonctionnement-container {
    overflow-x: auto;
    /* Ajoute le défilement horizontal */
  }

  .title-container h2 {
    width: 100%;
  }
}
</style>
