<template>
  <div>
    <navbar />
    <actionneurs-capteurs-image />
    <actionneurs-capteurs-presentation class="padding-resp mt100" />
    <actionneurs-capteurs-liste class="padding-resp mt100" />
    <actionneurs-capteurs-fonctionnement class="padding-resp mt100" />
    <foot class="mt100" />
  </div>
</template>

<script>
import foot from "@/components/FooterBis.vue";
import navbar from "@/components/NavBar.vue";
import ActionneursCapteursImage from "../../components/PageActionneursCapteurs/ActionneursCapteursImage.vue";
import ActionneursCapteursListe from "../../components/PageActionneursCapteurs/ActionneursCapteursListe.vue";
import ActionneursCapteursPresentation from "../../components/PageActionneursCapteurs/ActionneursCapteursPresentation.vue";
import ActionneursCapteursFonctionnement from "../../components/PageActionneursCapteurs/ActionneursCapteursFonctionnement.vue";

export default {
  components: {
    foot,
    navbar,
    ActionneursCapteursImage,
    ActionneursCapteursListe,
    ActionneursCapteursPresentation,
    ActionneursCapteursFonctionnement,
  },
  metaInfo() {
    return {
      title: this.$t("pageActionneurs.metaTitle"),
      link: [
        {
          rel: "canonical",
          href: `https://micetracking.com${this.$route.path}`,
        },
      ],
      meta: [
        {
          name: "description",
          content: this.$t("pageActionneurs.metaDescription"),
        },
        {
          name: "keywords",
          content: this.$t("pageActionneurs.metaKeywords"),
        },
      ],
    };
  },
  data() {
    return {
      fadeInText1: false,
      fadeInText2: false,
      fadeInText3: false,
      fadeInText4: false,
      fadeInImg1: false,
      fadeInImg2: false,
      fadeInImg3: false,
      fadeInDiv1: false,

      // ajoutez d'autres variables de données pour les autres textes (text-2, text-3, text-4) si nécessaire
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {},
};
</script>
