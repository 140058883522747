<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="11" lg="11" xl="9">
        <div class="row-between title-container">
          <h2>{{ $t("pageActionneurs.actuatorsTitle") }}</h2>
          <router-link to="/contact?subjectIndex=1" class="link-download" download>{{
            $t("button.datasheet")
          }}</router-link>
        </div>
        <div class="actionneurs-liste" ref="scrollContainer">
          <!-- Roue d'exercice -->
          <div class="item-actionneur">
            <div class="info-actionneur">
              <img src="@/assets/images/Roue3D.webp" alt="" />
              <div></div>
              <h3>{{ $t("pageActionneurs.actuators.exerciseWheel.name") }}</h3>
              <p class="dimension">
                {{ $t("pageActionneurs.actuators.exerciseWheel.dimension") }}
              </p>
              <p>{{ $t("pageActionneurs.actuators.exerciseWheel.type") }}</p>
            </div>
            <div class="detail-actionneur">
              <div style="width: 100%">
                <h3>{{ $t("pageActionneurs.actuators.exerciseWheel.name") }}</h3>
              </div>
              <ul>
                <li v-for="(item, index) in $t(
                  'pageActionneurs.actuators.exerciseWheel.details'
                )" :key="index">
                  {{ item }}
                </li>
              </ul>

              <router-link to="/contact" class="button-contact">{{
                $t("pageActionneurs.button.contact")
              }}</router-link>
            </div>
          </div>

          <!-- Air Puff -->
          <div class="item-actionneur">
            <div class="info-actionneur">
              <img src="@/assets/images/AirPuff3D.webp" alt="" />
              <div></div>
              <h3>{{ $t("pageActionneurs.actuators.airPuff.name") }}</h3>
              <p class="dimension">
                {{ $t("pageActionneurs.actuators.airPuff.dimension") }}
              </p>
              <p>{{ $t("pageActionneurs.actuators.airPuff.type") }}</p>
            </div>
            <div class="detail-actionneur">
              <div style="width: 100%">
                <h3>{{ $t("pageActionneurs.actuators.airPuff.name") }}</h3>
              </div>
              <ul>
                <li v-for="(item, index) in $t('pageActionneurs.actuators.airPuff.details')" :key="index">
                  {{ item }}
                </li>
              </ul>
              <router-link to="/contact" class="button-contact">{{
                $t("pageActionneurs.button.contact")
              }}</router-link>
            </div>
          </div>

          <!-- Porte -->
          <div class="item-actionneur">
            <div class="info-actionneur">
              <img src="@/assets/images/Tunnel3D.webp" alt="" />
              <div></div>
              <h3>{{ $t("pageActionneurs.actuators.door.name") }}</h3>
              <p class="dimension">
                {{ $t("pageActionneurs.actuators.door.dimension") }}
              </p>
              <p>{{ $t("pageActionneurs.actuators.door.type") }}</p>
            </div>
            <div class="detail-actionneur">
              <div style="width: 100%">
                <h3>{{ $t("pageActionneurs.actuators.door.name") }}</h3>
              </div>
              <ul>
                <li v-for="(item, index) in $t('pageActionneurs.actuators.door.details')" :key="index">
                  {{ item }}
                </li>
              </ul>
              <router-link to="/contact" class="button-contact">{{
                $t("pageActionneurs.button.contact")
              }}</router-link>
            </div>
          </div>

          <!-- Distributeur -->
          <div class="item-actionneur">
            <div class="info-actionneur">
              <img src="@/assets/images/Mangeoir3D.webp" alt="" />
              <div></div>
              <h3>{{ $t("pageActionneurs.actuators.dispenser.name") }}</h3>
              <p class="dimension">
                {{ $t("pageActionneurs.actuators.dispenser.dimension") }}
              </p>
              <p>{{ $t("pageActionneurs.actuators.dispenser.type") }}</p>
            </div>
            <div class="detail-actionneur">
              <div style="width: 100%">
                <h3>{{ $t("pageActionneurs.actuators.dispenser.name") }}</h3>
              </div>
              <ul>
                <li v-for="(item, index) in $t(
                  'pageActionneurs.actuators.dispenser.common.list'
                )" :key="index">
                  {{ item }}
                </li>
              </ul>
              <h5>{{ $t("pageActionneurs.actuators.dispenser.1.title") }}</h5>
              <ul>
                <li v-for="(item, index) in $t(
                  'pageActionneurs.actuators.dispenser.1.list'
                )" :key="index">
                  {{ item }}
                </li>
              </ul>
              <h5>{{ $t("pageActionneurs.actuators.dispenser.2.title") }}</h5>
              <ul style="margin-bottom: 30px">
                <li v-for="(item, index) in $t(
                  'pageActionneurs.actuators.dispenser.2.list'
                )" :key="index">
                  {{ item }}
                </li>
              </ul>
              <router-link to="/contact" class="button-contact">{{
                $t("pageActionneurs.button.contact")
              }}</router-link>
            </div>
          </div>
        </div>

        <div class="btn-container" v-if="isScrollable">
          <button class="scroll-btn left" @click="scrollLeft">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
              <path
                d="M21.559,12.062 L15.618,17.984 L21.5221,23.944 C22.105,24.533 22.1021,25.482 21.5131,26.065 C21.2211,26.355 20.8391,26.4999987 20.4571,26.4999987 C20.0711,26.4999987 19.6851,26.352 19.3921,26.056 L12.4351,19.034 C11.8531,18.446 11.8551,17.4999987 12.4411,16.916 L19.4411,9.938 C20.0261,9.353 20.9781,9.354 21.5621,9.941 C22.1471,10.528 22.1451,11.478 21.5591,12.062 L21.559,12.062 Z">
              </path>
            </svg>
          </button>
          <button class="scroll-btn right" @click="scrollRight">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
              <path
                d="M23.5587,16.916 C24.1447,17.4999987 24.1467,18.446 23.5647,19.034 L16.6077,26.056 C16.3147,26.352 15.9287,26.4999987 15.5427,26.4999987 C15.1607,26.4999987 14.7787,26.355 14.4867,26.065 C13.8977,25.482 13.8947,24.533 14.4777,23.944 L20.3818,17.984 L14.4408,12.062 C13.8548,11.478 13.8528,10.5279 14.4378,9.941 C15.0218,9.354 15.9738,9.353 16.5588,9.938 L23.5588,16.916 L23.5587,16.916 Z">
              </path>
            </svg>
          </button>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="11" lg="11" xl="9" class="mt100">
        <div class="row-between title-container">
          <h2>{{ $t("pageActionneurs.sensorsTitle") }}</h2>
          <router-link to="/contact?subjectIndex=1" class="link-download" download>{{
            $t("button.datasheet")
          }}</router-link>
        </div>
        <div class="actionneurs-liste liste2">
          <div class="item-actionneur">
            <div class="info-actionneur">
              <img src="@/assets/images/MTSLife3D.webp" alt="" />
              <div></div>
              <h3>{{ $t("pageActionneurs.sensors.mtsSmart.name") }}</h3>
              <p class="dimension">
                {{ $t("pageActionneurs.sensors.mtsSmart.dimension") }}
              </p>
              <p>{{ $t("pageActionneurs.sensors.mtsSmart.type") }}</p>
            </div>
            <div class="detail-actionneur">
              <h3>{{ $t("pageActionneurs.sensors.mtsSmart.name") }}</h3>
              <ul>
                <li v-for="(item, index) in $t('pageActionneurs.sensors.mtsSmart.details')" :key="index">
                  {{ item }}
                </li>
              </ul>
              <router-link to="/contact" class="button-contact">{{
                $t("pageActionneurs.button.contact")
              }}</router-link>
            </div>
          </div>
          <div class="item-actionneur">
            <div class="info-actionneur">
              <img src="@/assets/images/TunnelBalance.webp" alt="" />
              <div></div>
              <h3>{{ $t("pageActionneurs.sensors.tunnelBalance.name") }}</h3>
              <p class="dimension">
                {{ $t("pageActionneurs.sensors.tunnelBalance.dimension") }}
              </p>
              <p>{{ $t("pageActionneurs.sensors.tunnelBalance.type") }}</p>
            </div>
            <div class="detail-actionneur">
              <h3>{{ $t("pageActionneurs.sensors.tunnelBalance.name") }}</h3>
              <ul>
                <li v-for="(item, index) in $t(
                  'pageActionneurs.sensors.tunnelBalance.details'
                )" :key="index">
                  {{ item }}
                </li>
              </ul>
              <router-link to="/contact" class="button-contact">{{
                $t("button.contact")
              }}</router-link>
            </div>
          </div>
          <div class="item-actionneur">
            <div class="info-actionneur">
              <img src="@/assets/images/Roue3D.webp" alt="" />
              <div></div>
              <h3>{{ $t("pageActionneurs.sensors.freeWheel.name") }}</h3>
              <p class="dimension">
                {{ $t("pageActionneurs.sensors.freeWheel.dimension") }}
              </p>
              <p>{{ $t("pageActionneurs.sensors.freeWheel.type") }}</p>
            </div>
            <div class="detail-actionneur">
              <div style="width: 100%">
                <h3>{{ $t("pageActionneurs.sensors.freeWheel.name") }}</h3>
              </div>
              <ul>
                <li v-for="(item, index) in $t('pageActionneurs.sensors.freeWheel.details')" :key="index">
                  {{ item }}
                </li>
              </ul>
              <router-link to="/contact" class="button-contact">{{
                $t("button.contact")
              }}</router-link>
            </div>
          </div>
          <div class="item-actionneur">
            <div class="info-actionneur">
              <img src="@/assets/images/ObjetsGéométriques.webp" alt="" />
              <div></div>
              <h3>{{ $t("pageActionneurs.sensors.funObjects.name") }}</h3>
              <p class="dimension"></p>
              <p>{{ $t("pageActionneurs.sensors.funObjects.type") }}</p>
            </div>
            <div class="detail-actionneur">
              <div style="width: 100%">
                <h3>{{ $t("pageActionneurs.sensors.funObjects.name") }}</h3>
              </div>
              <h5>{{ $t("pageActionneurs.sensors.funObjects.boule.title") }}</h5>
              <ul>
                <li v-for="(item, index) in $t(
                  'pageActionneurs.sensors.funObjects.boule.list'
                )" :key="index">
                  {{ item }}
                </li>
              </ul>
              <h5>{{ $t("pageActionneurs.sensors.funObjects.simple.title") }}</h5>
              <ul>
                <li v-for="(item, index) in $t(
                  'pageActionneurs.sensors.funObjects.simple.list'
                )" :key="index">
                  {{ item }}
                </li>
              </ul>
              <h5>{{ $t("pageActionneurs.sensors.funObjects.intelligent.title") }}</h5>
              <ul class="mb-25">
                <li v-for="(item, index) in $t(
                  'pageActionneurs.sensors.funObjects.intelligent.list'
                )" :key="index">
                  {{ item }}
                </li>
              </ul>
              <router-link to="/contact" class="button-contact">{{
                $t("button.contact")
              }}</router-link>
            </div>
          </div>
        </div>
        <div class="btn-container" v-if="isScrollable">
          <button class="scroll-btn left" @click="scrollLeft2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
              <path
                d="M21.559,12.062 L15.618,17.984 L21.5221,23.944 C22.105,24.533 22.1021,25.482 21.5131,26.065 C21.2211,26.355 20.8391,26.4999987 20.4571,26.4999987 C20.0711,26.4999987 19.6851,26.352 19.3921,26.056 L12.4351,19.034 C11.8531,18.446 11.8551,17.4999987 12.4411,16.916 L19.4411,9.938 C20.0261,9.353 20.9781,9.354 21.5621,9.941 C22.1471,10.528 22.1451,11.478 21.5591,12.062 L21.559,12.062 Z">
              </path>
            </svg>
          </button>
          <button class="scroll-btn right" @click="scrollRight2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
              <path
                d="M23.5587,16.916 C24.1447,17.4999987 24.1467,18.446 23.5647,19.034 L16.6077,26.056 C16.3147,26.352 15.9287,26.4999987 15.5427,26.4999987 C15.1607,26.4999987 14.7787,26.355 14.4867,26.065 C13.8977,25.482 13.8947,24.533 14.4777,23.944 L20.3818,17.984 L14.4408,12.062 C13.8548,11.478 13.8528,10.5279 14.4378,9.941 C15.0218,9.354 15.9738,9.353 16.5588,9.938 L23.5588,16.916 L23.5587,16.916 Z">
              </path>
            </svg>
          </button>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isScrollable: false,
    };
  },
  methods: {
    scrollLeft() {
      const container = this.$el.querySelector(".actionneurs-liste");
      container.scrollBy({
        left: -300, // Distance à défiler
        behavior: "smooth",
      });
    },
    scrollRight() {
      const container = this.$el.querySelector(".actionneurs-liste");
      container.scrollBy({
        left: 300, // Distance à défiler
        behavior: "smooth",
      });
    },
    scrollLeft2() {
      const container = this.$el.querySelector(".liste2");
      container.scrollBy({
        left: -300, // Distance à défiler
        behavior: "smooth",
      });
    },
    scrollRight2() {
      const container = this.$el.querySelector(".liste2");
      container.scrollBy({
        left: 300, // Distance à défiler
        behavior: "smooth",
      });
    },
    checkIfScrollable() {
      const scrollContainer = this.$refs.scrollContainer;
      this.isScrollable = scrollContainer.scrollWidth > scrollContainer.clientWidth;
    },
    scrollLeft() {
      this.$refs.scrollContainer.scrollBy({ left: -200, behavior: "smooth" });
    },
    scrollRight() {
      this.$refs.scrollContainer.scrollBy({ left: 200, behavior: "smooth" });
    },
  },
  mounted() {
    this.checkIfScrollable();
    window.addEventListener("resize", this.checkIfScrollable);
  },
  computed: {
    downloadLink() {
      return this.$i18n.locale === "fr"
        ? "/pdf/MiceTrackingDatasheetFR.pdf"
        : "/pdf/MiceTrackingDatasheetUS.pdf";
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIfScrollable);
  },
};
</script>

<style scoped>
.title-container {
  align-items: end;
}

.actionneurs-liste {
  display: flex;
  margin-top: 60px;
  overflow-x: hidden;
  /* Ajoute le défilement horizontal */
  padding-bottom: 20px;
  gap: 50px;
}

.item-actionneur {
  background-color: var(--white);
  width: 300px;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  min-height: 500px;
  /* Ajoute une hauteur minimale */
  overflow: hidden;
  /* Empêche le contenu de déborder */
  flex-shrink: 0;
  /* Empêche la réduction de la taille des éléments */
}

.info-actionneur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  text-align: left;
}

.detail-actionneur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  padding: 15px;
  text-align: left;
  overflow-y: scroll;
}

.detail-actionneur ul {
  padding-left: 10px;
  margin-top: 10px;
}

.detail-actionneur ul li {
  text-align: left;
  list-style: disc;
}

.detail-actionneur h5 {
  margin-top: 12px;
  text-align: left;
}

.info-actionneur {
  opacity: 1;
  visibility: visible;
}

.item-actionneur:hover .info-actionneur {
  opacity: 0;
  visibility: hidden;
}

.item-actionneur:hover .detail-actionneur {
  opacity: 1;
  visibility: visible;
}

.item-actionneur div {
  text-align: center;
}

.item-actionneur div img {
  width: 80%;
  margin-top: 35px;
  margin-bottom: 60px;
}

.info-actionneur div {
  width: 80%;
  background-color: black;
  height: 1px;
  margin: 0 auto;
}

.info-actionneur h3 {
  margin-top: 20px;
}

.info-actionneur h4 {
  margin-bottom: 25px;
}

.detail-actionneur h3,
.detail-actionneur p {
  text-align: left;
  margin-left: 0;
}

.detail-actionneur h3 {
  margin-top: 10px;
  margin-bottom: 25px;
}

.detail-actionneur a {
  margin-top: auto;
}

.button-contact {
  background-color: var(--vertfonce);
  padding: 0.2vw 2vw;
  border-radius: 20px;
  color: white;
  border: solid 2px var(--vertfonce);
  font-family: "Roboto", Regular;
  font-size: 16px;
  transition: background 300ms ease-in-out;
  text-decoration: none;
}

.button-contact:hover {
  background-position: 100%;
  color: var(--vertfonce);
  background-color: transparent;
}

.dimension {
  font-family: "Roboto";
  font-style: italic;
  font-size: 16px;
}

.btn-container {
  position: relative;
  top: 20px;
}

.scroll-btn {
  background-color: var(--grisclair);
  border-radius: 100%;
  width: 36px;
  height: 36px;
  color: black;
  font-size: 30px;
  cursor: pointer;
  z-index: 1;
  align-items: center;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.scroll-btn:hover {
  opacity: 1;
}

.scroll-btn.left {
  left: -40px;
  /* Ajustez la position de la flèche gauche */
}

.scroll-btn.right {
  right: -20px;
  /* Ajustez la position de la flèche droite */
}

@media (max-width: 960px) {
  .actionneurs-liste {
    overflow-x: auto;
  }
}

@media (max-width: 767px) {
  .btn-container {
    display: none;
  }

  .actionneurs-liste {
    display: flex;
    /* Ajout pour permettre le centrage */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* Centre les éléments horizontalement */
  }

  .item-actionneur {
    margin-top: 30px;
    width: 300px;
    /* Ajustez la largeur si nécessaire */
  }
}
</style>
